import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PageLoader from "../shared/common/page-loader";
import ErrorBoundaryRoute from "../shared/errors/ErrorBoundaryRoute";
import NotFoundPage from "../shared/errors/PageNotFound";
import IndexLayout from "../layouts";

const HomeContainer = lazy(() => import("../modules/home/container"));
const AboutContainer = lazy(() => import("../modules/about/container"));
const ComprehensiveContainer = lazy(() => import("../modules/ComprehensiveInsurance/container"));
const ThirdPartyContainer = lazy(() => import("../modules/ThirdPartyInsurance/container"));
const PrivacyPolicyContainer = lazy(() => import("../modules/PrivacyPolicy/container"));
const InsurersContainer = lazy(() => import("../modules/Insurers/container"));

const JubileeInsurance = lazy(() => import("../modules/InsurersName/JubileeInsurance/container"));


const appRoutes = [
  {
    path: "/",
    component: HomeContainer,
    exact: true,
  },
  {
    path: "/about",
    component: AboutContainer,
    exact: true,
  },
  {
    path: "/comprehensive-insurance",
    component: ComprehensiveContainer,
    exact: true,
  },
  {
    path: "/third-party-insurance",
    component: ThirdPartyContainer,
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicyContainer,
    exact: true,
  },
  {
    path: "/insurers/jubilee-insurance",
    component: JubileeInsurance,
    exact: true,
  },
  {
    path: "/insurers",
    component: InsurersContainer,
    exact: true,
  },

];

const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <IndexLayout>
          <Switch>
            {appRoutes.map((route) => (
              <ErrorBoundaryRoute
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </Suspense>
    </BrowserRouter>
  );
};

Routes.propTypes = {};

Routes.defaultProps = {};

export default React.memo(Routes);
