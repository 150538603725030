import { makeStyles } from "@material-ui/core";
import { textPrimary, textWhite } from "../../../themes/colors";

export const topHeaderStyles = makeStyles((theme) => ({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 960,
  //     lg: 1280,
  //     xl: 1920,
  //   },
  // },
  imgResponsive: {
    maxWidth: "100%",
  },

  topStrip: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '60px',
    }
  },
  leftContainer: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down('sm')]: {
      '& > div:first-child': {
        display: 'none',
      },
    }

  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: 40,
  },
  iconContainer: {
    paddingRight: 5,
    display: 'flex'
  },
  labelContainer: {},
  text: {
    fontSize: 12,
    color: textPrimary,
    fontWeight: 800,

  },
  helperText: {
    fontSize: 12,
    paddingLeft: 4,
    color: "#F6FFED",
    fontWeight: 500,
    textDecoration: 'none'
  },
  helperIcon: {
    color: "#FCC830"
  },
  iconsize: {
    fontSize: '1.5rem',
    color: '#FCC830',
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
}));

export const bottomHeaderStyles = makeStyles((theme) => ({
  container: {
    background: 'transparent',
    margin: theme.spacing(3, 0)
  },
  brandContainer: {
    [theme.breakpoints.down('md')]: {
      // maxWidth: "175px",
    },
    '& a': {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      '& span': {
        paddingLeft: '28px',
        color: '#133D59',
        fontSize: '36px',
        fontWeight: '600',
        [theme.breakpoints.down('md')]: {
          fontSize: '35px',
          paddingLeft: '15px',
        },

      }
    },
    '& img': {
      maxWidth: '38px',
    },
  },
  [theme.breakpoints.down('md')]: {
    img: {
      width: "100%",
    }
  },
  brandContainerMobile: {
    maxWidth: "100%",
    padding: '10px 15px',
    borderBottom: '1px solid #c8c6c6',
    '& a': {
      maxWidth: "145px",
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      '& img': {
        maxWidth: '38px',
      },
      '& span': {
        paddingLeft: '15px',
        color: '#133D59',
        fontSize: '30px',
        fontWeight: '600',
      }
    }
  },
  navWrapper: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    }
  },
  navbarContainer: {
    flexGrow: 1,
    textAlign: 'right',
    '& > a:first-child': {
      marginLeft: 0
    },
    '& > a:last-child': {
      marginRight: 0,
      paddingRight: 0,
    },
    '& .active': {
      borderBottomColor: theme.palette.secondary.main,
      borderBottomStyle: 'solid',
      borderBottomWidth: 3,
      fontWeight: 700,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },


  navlink: {
    textDecoration: 'none !important',
    padding: '6px 0 !important',
    margin: theme.spacing(0, 2),
    paddingRight: '4 !important',
    display: 'inline-block !important',
    width: 'auto !important',
    position: 'relative !important',
    borderBottom: '3px solid transparent',

    '&:hover, &:focus': {
      background: 'transparent !important',
    },

    '& span': {
      fontSize: 14,
      fontWeight: 600,
      color: "#133D59",
    },



    '& .hoverMenu': {
      listStyle: 'none',
      margin: 0,
      background: '#133D59',
      padding: '20px',
      minWidth: '220px',
      position: 'absolute',
      zIndex: '99',
      marginTop: '0px',
      transform: 'translateY(30px)',
      transition: '0.25s linear',
      opacity: '0',
      visibility: 'hidden',
      left: '-60px',

      '& li a': {
        fontSize: '13px',
        fontWeight: '600',
        lineHeight: '35px',
        color: textWhite,
        textDecoration: 'none',
        border: '0',
      },

      '&::before': {
        content: '""',
        background: '#133D59',
        width: '25px',
        height: '25px',
        display: 'inline-block',
        transform: 'rotate(45deg)',
        position: 'absolute',
        top: '-7px',
        left: ' -45px',
        right: '0',
        margin: ' 0 auto',
      },
    },
    '&:hover .hoverMenu': {
      transform: 'translateY(5px)',
      transition: '0.25s linear',
      opacity: '1',
      visibility: 'visible',

    },
  },
  iconButton: {
    color: theme.palette.secondary.main,
    display: 'inline-block',
    width: 'auto'
  },
  mobileMenu: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    }
  },
  sidemenu: {
    width: '240px'
  },

  sidemenuList: {
    paddingTop: '0px !important',
    '& .MuiListItem-root': {
      display: 'block!important',
      paddingTop: ' 6px',
      paddingBottom: '6px',
      borderBottom: '1px solid #d0d0d0',
    },
    '& .toggleMenu': {
      margin: '10px -16px 0',
      padding: '0',
      listStyle: 'none',
    },
    '& .toggleMenu li': {
      paddingTop: ' 8px',
      paddingBottom: '8px',
      borderTop: '1px solid #d0d0d0',
      '& a': {
        color: '#133D59',
        textDecoration: 'none',
        padding: '0 16px'
      }
    }

  },

  




}));

export const countryFlagStyle = makeStyles((theme) => ({
  flagListbox:{
    '& .MuiMenu-list':{
      paddingTop: '0',
      paddingBottom: '0'
    },
    '& .MuiButtonBase-root':{
      display: 'block',
      padding: '8px',

      '&:first-child': {
        borderBottom: '1px solid #c4c8ca',
        borderRadius: '0'
      },
    },


    
  }
}));

export default topHeaderStyles;
