import classname from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { AppLogoIcon } from "../../../assets/svgs";
import useStyles from "./styles";

const Loader = ({ large, small }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <AppLogoIcon
        className={classname(classes.icon, {
          [classes.largeSize]: large,
          [classes.smallSize]: small,
        })}
      />
    </div>
  );
};

Loader.propTypes = {
  large: PropTypes.bool,
  small: PropTypes.bool,
};

Loader.defaultProps = {
  large: false,
  small: false,
};

export default Loader;
