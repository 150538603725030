import React from "react";
import PropTypes from "prop-types";
import Header from '../../shared/common/header';
import Footer from '../../shared/common/footer';
import useStyles from "./styles";

const PublicLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Header.TopHeader />
      <Header.BottomHeader />
      <div className={classes.content}>{children}</div>
      <Footer />
    </>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

PublicLayout.defaultProps = {};

export default PublicLayout;
