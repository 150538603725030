import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import reducers from "../modules/reducers";

const rootReducer = combineReducers({
  form: formReducer,
  quote: reducers.quoteReducer,
});

export default rootReducer;
