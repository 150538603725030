import React from "react";
import useStyles from "./styles";
import Loader from "../loader";

const PageLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Loader large />
    </div>
  );
};

PageLoader.propTypes = {};

export default PageLoader;
