import React from "react";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import useStyles from "./styles";

const Social = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <IconButton disableRipple href="https://twitter.com/Vehicle_UG" target="_blank" className={classes.iconButton}>
        <FontAwesomeIcon className={classes.iconSmallsize} icon={faTwitter} />
      </IconButton>
      <IconButton disableRipple href="https://www.facebook.com/vehicleuganda" target="_blank" className={classes.iconButton}>
        <FontAwesomeIcon className={classes.iconSmallsize} icon={faFacebookF} />
      </IconButton>

      <IconButton disableRipple href="https://www.instagram.com/vehicle.ug/" target="_blank" className={classes.iconButton}>
        <FontAwesomeIcon className={classes.iconSmallsize} icon={faInstagram} />
      </IconButton> 

    </div>
  );
};

Social.propTypes = {};

export default Social;
