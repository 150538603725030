export default {
  palette: {
    secondary: {
      main: "#133D59"
    },
    primary: {
      main: "#0887ED"
    },
    background: {
      default: "#E8E8E8",
      light: "#E8E8E8"
    }
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
    modal: 1300
  }
};
