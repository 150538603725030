import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  smallSize: {
    fontSize: 40,
  },
  largeSize: {
    fontSize: 80,
  },
  "@keyframes loading": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  icon: {
    animation: "$loading 2s linear infinite",
  },
}));
