import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Container,
} from "@material-ui/core";
// import SearchIcon from '@material-ui/icons/Search';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import brandIcon from "../../../assets/images/logo-symbol.svg";
import { bottomHeaderStyles } from "./styles";


const BottomHeader = () => {
  const classes = bottomHeaderStyles();

  const location = useLocation();

  const isActive = (value) => (location.pathname === value ? 'active' : '');

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // Search Popup
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className={classes.sidemenu}
    >
      <div className={classes.brandContainerMobile}>
        <Link to="/">
          <img src={brandIcon} className={classes.imgResponsive} alt="Vehicle" />
          <span>VEHICLE</span>
    
        </Link>

      
        
      </div>

      <List className={classes.sidemenuList}>
        <ListItem component={Link} to="/" className={isActive('/')}>
          <ListItemText primary="Home" />
        </ListItem>

        <ListItem button component={Link} to="/comprehensive-insurance">
          <ListItemText primary="Comprehensive Insurance" />
        </ListItem>
        <ListItem button component={Link} to="/third-party-insurance">
          <ListItemText primary="Third Party Insurance" />
        </ListItem>

        {/* <ListItem button component={Link} to="#">
          <ListItemText primary="Garagaes" />
        </ListItem> */}

        <ListItem button component={Link} to="/about" className={isActive('/about')}>
          <ListItemText primary="About" />
        </ListItem>

        <ListItem button component={Link} to="/privacy-policy" className={isActive('/about')}>
          <ListItemText primary="Privacy Policy" />
        </ListItem>
        <ListItem button component={Link} to="#" className={isActive('/')}>
          <ListItemText primary="Terms & Conditions" />
        </ListItem>

        <ListItem button component={Link} to="/" onClick={event => window.location.href = 'https://insure.vehicle.co.ug'} >
          <ListItemText primary="Get Quote" />
        </ListItem>
      </List>


    </div>
  );


  return (

    <AppBar
      component="header"
      position="static"
      color="inherit"
      elevation={0}
      className={classes.container}
    >
      <Container fixed>
        <Toolbar disableGutters className={classes.navWrapper}>
          <div className={classes.brandContainer}>
            <Link to="/">
              <img src={brandIcon} className={classes.imgResponsive} alt="Vehicle" />
              <span>VEHICLE</span>
            </Link>
          </div>
          <nav className={classes.navbarContainer}>

            <List>
              <ListItem component={NavLink} to="/" className={classes.navlink} activeClassName={isActive('/')}>
                <ListItemText primary="Home" />
              </ListItem>

              <ListItem button component={NavLink} to="#" className={classes.navlink} activeClassName={isActive('/comprehensive-insurance') || isActive('/third-party-insurance')}>
                <ListItemText primary="Types of Insurance" />
                <ul className="hoverMenu">
                  <li><NavLink to="/comprehensive-insurance">Comprehensive Insurance</NavLink></li>
                  <li><NavLink to="/third-party-insurance">Third Party Insurance</NavLink></li>
                </ul>
              </ListItem>

              {/* <ListItem button component={NavLink} to="#" className={classes.navlink} activeClassName={isActive('/garagaes')}>
                <ListItemText primary="Garagaes" />
              </ListItem> */}

              <ListItem button component={NavLink} to="/insurers" className={classes.navlink} activeClassName={isActive('/insurers')}>
                <ListItemText primary="Insurers" />
              </ListItem>



              <ListItem button component={NavLink} to="#" className={classes.navlink} activeClassName={isActive('/about') || isActive('/privacy-policy')}>
                <ListItemText primary="About" />
                <ul
                  className="hoverMenu"
                >
                  <li><NavLink to="/about">About Vehicle</NavLink></li>
                  <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                  {/* <li><NavLink to="#">Terms & Conditions</NavLink></li> */}
                </ul>
              </ListItem>

              <ListItem button component={NavLink} to="/" onClick={event => window.location.href = 'https://insure.vehicle.co.ug/'} className={classes.navlink} activeClassName={isActive('/get-quote')}>
                <ListItemText primary="Get Quote" />
              </ListItem>

              <ListItem button className={classes.iconButton} aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                {/* <SearchIcon /> */}
                {/* <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography className={classes.typography}>The content of the Popover.</Typography>
                </Popover> */}
              </ListItem>
            </List>
          </nav>

          <div className={classes.mobileMenu}>
            <Button onClick={toggleDrawer('left', true)}>
              <MenuRoundedIcon fontSize="large" />
            </Button>
            <Drawer anchor='left' open={state.left} onClose={toggleDrawer('left', false)}>
              {list('left')}
            </Drawer>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );


};

BottomHeader.propTypes = {};

export default BottomHeader;
