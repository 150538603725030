import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  container: {
    minHeight: "100vh",
    position: "absolute",
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff"
  },
}));
