import { createMuiTheme } from "@material-ui/core";
import defaultTheme from "./default";

const overrides = {
  typography: {
    fontFamily: "'Quicksand', sans-serif",
    h1: {
      fontSize: "3.3rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    h2: {
      fontSize: "2rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    h3: {
      fontSize: "1.64rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    h4: {
      fontSize: "1.5rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    h5: {
      fontSize: "1.285rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    h6: {
      fontSize: "1.142rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    subtitle2: {
      fontFamily: "'Quicksand', sans-serif",
    },
    subtitle1: {
      fontFamily: "'Quicksand', sans-serif",
    },
    caption: {
      fontFamily: "'Quicksand', sans-serif",
    },
    button: {
      fontFamily: "'Quicksand', sans-serif",
    },
    body1: {
      fontFamily: "'Quicksand', sans-serif",
    },
    body2: {
      fontFamily: "'Quicksand', sans-serif",
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "transparent",
          fontFamily: "'Quicksand', sans-serif",
          fontSize: 16,
          lineHeight: '24px',
          color: '#133D59',
          width: '100%',
        },
        p: {
          lineHeight: '35px'
      },
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
    }
      
      }
    }
  }
};

export default {
  default: createMuiTheme({ ...defaultTheme, ...overrides })
};
