import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    paddingTop: 60,
  },
  spacingContainer: {
   // padding: theme.spacing(0, 10)
  },

  vehicleWidget: {
    '& a': {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      '& span': {
        paddingLeft: '28px',
        [theme.breakpoints.down('md')]: {
          paddingLeft: '20px',
        },
        color: '#fff',
        fontSize: '38px',
        fontWeight: '600',
      }
    }
  },
  vehicleWidgetinfo: {
    color: "#fff",
    fontSize: 14,
    lineHeight: '22px',
 
  },
  
  title: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "24px",
    color: "#fff",
    padding: theme.spacing(1.3, 0),
  },
  navlink: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "inherit",
    textDecoration: "none",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    '&:hover': {
      color: "#0887ED",
    }
  },
  icon: {
    color: theme.palette.primary.main,
    marginLeft: -6,
  },
  addressContainer: {
    display: "flex",
    flexDirection: "column",
    color: "#fff",
  },


  widgetItem: {
    display: "block",

    [theme.breakpoints.down('md')]: {
    paddingBottom: "0!important",
    paddingTop: "15px!important",

    },
  },

  address: {
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    fontSize: 12
  },
  navbarContainer: {
    display: "flex",
    flexDirection: "column",
  },
  storeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",

    '& svg': {
      fontSize: "1.4rem",
     },

     '& button:first-child': {
     paddingLeft: "0"
     },
  },
  button: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  image: {
    width: 100,
  },
  primaryText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "22px",
    color: theme.palette.primary.main
  },
  secondaryText: {
    fontSize: 12,
    fontWeight: 500,
    color: "#fff",
    lineHeight: "22px",
    textDecoration: 'none'
  },
  copyrightWrap: {
    padding: "0px 25px!important",
    textAlign: "center",
    marginBottom: "20px",
    
    '& p': {
     color: '#fff',
     fontSize: 14,
    },
  },
  mobileNo_space:{
    [theme.breakpoints.down('md')]: {
     width: '100% !important',
     margin: '0!important'
    },
  },

}));
