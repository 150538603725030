const initialState = {
  selectedVehicle: null,
  selectedStep: 1,
};

export default function quoteReducer(state = initialState, action) {
  switch (action.type) {
    case "SELECT_VEHICLE_TYPE":
      return {
        ...state,
        selectedVehicle: action.payload,
      };
    case "SELECT_STEP":
      return {
        ...state,
        selectedStep: action.payload,
      };
    default:
      return state;
  }
}
