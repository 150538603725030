import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { loadIcons } from './config/icon-loader';
import Root from './root/Root';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';

loadIcons();

const tagManagerArgs = {
  gtmId: 'GTM-NCHCFC9',
  dataLayerName: 'PageDataLayer',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Root store={configureStore} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
