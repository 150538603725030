import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import Themes from "../themes";
import ErrorBoundary from "../shared/errors/ErrorBoundary";
import Routes from '../routes';

const Root = ({ store }) => (
  <ErrorBoundary>
    <Provider store={store}>
      <ThemeProvider theme={Themes.default}>
        <CssBaseline />
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>
);

Root.propTypes = {
  store: PropTypes.instanceOf(Object),
};

Root.defaultProps = {
  store: {},
};

export default Root;
