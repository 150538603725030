import React from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import playstoreImage from "../../../assets/images/applestore.png";
import applestoreImage from "../../../assets/images/playstore.png";
import logoWhite from "../../../assets/images/logo-white.svg";
import Social from "../../social";

import useStyles from "./styles";

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.container}>
      <Container fixed className={classes.spacingContainer}>
        <Grid container spacing={8} className={classes.mobileNo_space}>

          <Grid item test md={3} className={classes.widgetItem}>
            <div className={classes.vehicleWidget}>
              <a href="/">
                <img src={logoWhite} alt="" />
                <span>VEHICLE</span>
              </a>

              <p className={classes.vehicleWidgetinfo}>
                Vehicle is your one stop shop for all
                motor requirements, insurance cover &
                road safety value adds.
              </p>
            </div>

          </Grid>
          <Grid item md={4} className={classes.widgetItem}>
            <Typography className={classes.title} variant="h4">
              Contact Us
            </Typography>
            <div className={classes.addressContainer}>
              <address className={classes.address}>
                Trust Tower, Kyadondo Road, Kampala, Uganda

              </address>
              <Typography className={classes.primaryText}>
                Phone:
                {" "}
                <Typography href="tel:  +256 200 900859" className={classes.secondaryText} component="a">
                  +256 200 900859
                </Typography>
                {" / "}
                <Typography href="tel:  +256 780 260294" className={classes.secondaryText} component="a">
                  +256 780 260294
                </Typography>
              </Typography>
              <Typography className={classes.primaryText}>
                Email:
                {" "}
                <Typography className={classes.secondaryText} href="mailto:info@kenbright.co.ug" component="a">
                  info@kenbright.co.ug
                </Typography>
              </Typography>
              <Typography className={classes.primaryText}>
                Policy Administration:
                {" "}
                <br />
                <Typography component="span" className={classes.secondaryText}>

                  All Policies are administered by Kenbright Insurance Brokers
                </Typography>
              </Typography>
            </div>
          </Grid>

          <Grid item md={3} className={classes.widgetItem}>
            <Typography className={classes.title} variant="h4">
              Download the App
            </Typography>
            <div className={classes.storeContainer}>
              <Button className={classes.button} disableRipple>
                <img
                  className={classes.image}
                  src={playstoreImage}
                  alt="playstore"
                />
              </Button>
              <Button className={classes.button} disableRipple>
                <img
                  className={classes.image}
                  src={applestoreImage}
                  alt="applestore"
                />
              </Button>

              <Social instagram />
            </div>
          </Grid>

          <Grid item md={2} className={classes.widgetItem}>
            <Typography className={classes.title} variant="h4">
              Links
            </Typography>
            <div className={classes.navbarContainer}>
              <Typography
                color="inherit"
                component={Link}
                to="/comprehensive-insurance"
                className={classes.navlink}
              >
                {/* <ChevronRightIcon className={classes.icon} fontSize="small" /> */}
                Comprehensive Insurance
              </Typography>
              <Typography
                color="inherit"
                component={Link}
                to="/third-party-insurance"
                className={classes.navlink}
              >
                {/* <ChevronRightIcon className={classes.icon} fontSize="small" /> */}
                Third Party Insurance
              </Typography>
              <Typography
                color="inherit"
                component={Link}
                to="/"
                className={classes.navlink}
              >
                {/* <ChevronRightIcon className={classes.icon} fontSize="small" /> */}
                Garages
              </Typography>
              <Typography
                href="https://vehicle.co.ug/"
                target="_blank"
                color="inherit"
                component={Link}
                className={classes.navlink}
              >
                {/* <ChevronRightIcon className={classes.icon} fontSize="small" /> */}
                IRA Uganda
              </Typography>
              <Typography
                color="inherit"
                component={Link}
                to="/privacy-policy"
                className={classes.navlink}
              >
                {/* <ChevronRightIcon className={classes.icon} fontSize="small" /> */}
                Privacy Policy
              </Typography>


            </div>
          </Grid>
          <Grid item md={12} className={classes.copyrightWrap}>
            <div className={classes.copyright}>
              <p>
                Copyright ©
                {' '}
                {(new Date().getFullYear())}
                {' '}
                Distributed Output Technology Systems (D.O.T.S) Limited. All rights reserved.
              </p>
            </div>
          </Grid>

        </Grid>
      </Container>
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
