import { makeStyles } from "@material-ui/core";

export default makeStyles({
  container: {
    paddingRight: 5,
  },

  '.topStrip':{
    minHeight: 60

  },

  iconButton: {
    color: "#fff",
    "&:hover": {
      background: "transparent",
      color: '#0887ED',
    },
    
  },
  iconSmallsize: {
    fontSize: '0.85rem',
  },
  button: {
    border: "none"
  }
});
